import styles from './GuestInstallmentsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './GuestInstallmentsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
function GuestInstallmentsTable(props:any) {
    const {items} = props
    const {t} = useTranslation()
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Client Name')}</th>
                    <th>{t('Device Name')}</th>
                    <th>{t('Paid')}</th>
                    <th>{t('Remains')}</th>
                </thead>
                <tbody>
                    {
                        items && items?.map((item:any,index:number)=>(
                            <tr key={index}>
                                <td>
                                    <Link to={`/our-clients/client-details/${item?.user_id}`} className={styles['appointments__table-link']}>
                                        {item?.user_name}
                                    </Link>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}>{item?.product_name}</p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.price} {t('AED')}</p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.paid_amount} {t('AED')}</p>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default GuestInstallmentsTable