import { useTranslation } from 'react-i18next'
import styles from './AddUpdateClientProducts.module.css'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { Container } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
function AddUpdateClientProducts() {
  const {t} =useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const [price,setPrice] =useState('')
  const [paidAmount,setPaidAmount] =useState('')
  const [product,setProduct] =useState<any>()
  const [productId,setProductId] =useState('')
  
  const [warrantyEndDate,setWarrantyEndDate] =useState( moment(new Date()).format('yyyy-MM-DD') )
  const [warrantyStartDate,setWarrantyStartDate] =useState(moment(new Date()).format('yyyy-MM-DD'))

  const [isLoading,setIsLoading] =useState(false)
  const [products,setProducts]=useState([])
  
  
  function validateInputs(){
    let status =true
    if(!product){
      toast.error(t('Product Is Required'))
      status=false
    }
    if(!warrantyStartDate){
      toast.error(t('Warranty Start Date Is Required'))
      status=false
    }
    if(!price){
      toast.error(t('Price Is Required'))
      status=false
    }
    return status
  }

  function addUpdateItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('product_id',productId)
    formData.append('price',price)
    formData.append('warranty_start_date',warrantyStartDate)
    formData.append('warranty_end_date',warrantyEndDate)
    
    axiosConfig.post(`/user/add-product-to-user/${params?.id}`,formData,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res=>{
      setIsLoading(false)
      toast.success(t(`User Product Added Successfully`))
      navigate(`/our-clients/client-products/${params?.id}`)
    }).catch(err=>{
      setIsLoading(false)
      toast.error(t('Something went wrong'))
    })
  }

  function getAllProducts(){
    axiosConfig.get(`/product/all-products-without-pagination`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(res=>{
      setProducts(res.data?.data)
    }).catch(err=>{
      console.log(err)
    })
  }
  function handleChangeProduct(value:any){
    const productData = products?.filter((product:any)=>{
      return product?.id==value
    })?.[0]
    setProductId(value);
    setProduct(productData);
  }
  function handleChangeWarrantyDate(){
    let time = (new Date((new Date(warrantyStartDate))).getTime() + ((Number(product?.warranty_period)||0) * 31556926000 ))
    setWarrantyEndDate(moment(new Date(time)).format('yyyy-MM-DD') as any)
  }
  useEffect(()=>{
    getAllProducts()
  },[])
  useEffect(()=>{
    if(product?.warranty_period){
      handleChangeWarrantyDate()
    }
  },[warrantyStartDate,product])
  return (
    <>
      <section className={styles['form-wrapper']}>
        <Container>
          <div className={styles['form__content-wrapper']}>
            <h1 className={styles['form__content-title']}>{t('Add Product')}</h1>
            <form className={styles['form__content-form']}>
              <div className={styles['form__content-form-inputs-wrapper']}>
                <select 
                    className={styles['form__content-form-input']}
                    value={productId}
                    onChange={(e)=>handleChangeProduct(e.target.value)}
                  >
                    <option value=''>{t('Please Select Product')}</option>
                    {
                      products?.map((product:any)=>(
                        <option value={product?.id} key={product?.id}>{product?.title}</option>
                      ))
                    }
                  </select>
                <input 
                  type='number'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Price')}`}
                  value={price}
                  onChange={(e)=>setPrice(e.target.value)}
                />
                <input 
                  type='date'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Warranty Date')}`}
                  value={warrantyStartDate as any}
                  onChange={(e)=>setWarrantyStartDate(e.target.value  as any)}
                />
                <input 
                  type='date'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Warranty End Date')}`}
                  value={warrantyEndDate as any}
                  onChange={(e)=>setWarrantyEndDate(e.target.value as any)}
                  disabled
                />
              </div>
              
              <button 
                type='button' 
                className={styles['form__content-form-submit']}
                onClick={addUpdateItem} 
                disabled={isLoading}>
                {
                  !isLoading?t('Add Product')
                  :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                }
              </button> 
            </form>
          </div>
        </Container>
        
      </section>
    </>
  )
}

export default AddUpdateClientProducts