import styles from './GuestIncomesReportsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './GuestIncomesReportsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import { axiosConfig } from 'utils/axiosConfig'
import { useEffect, useState } from 'react'
function GuestIncomesReportsTable(props:any) {
    const {items}= props
    const {t} =useTranslation()
    // const [items,setItems]=useState([])
    // const [currentPage,setCurrentPage]=useState(1)
    // const [pages,setPages]=useState(null)
    // function getAllItems(){
    //     axiosConfig.get(`/orders/month-payments?month=${month}&year=${year}`,{
    //         headers: {
    //             "Authorization": `Bearer ${localStorage.getItem('token')}`,
    //             
    //         }
    //     }).then(res=>{
    //         setItems(res.data?.data?.orders)
    //         // setPages(res.data?.data?.pages)
    //     }).catch(err=>{
    //         console.log(err)
    //     })
    // }
    // useEffect(()=>{
    //     getAllItems()
    // },[month,year])
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Client Name')}</th>
                    <th>{t('Client Phone')}</th>
                    <th>{t('Paid')}</th>
                    <th>{t('Date')}</th>
                    <th>{t('Payment Method')}</th>
                </thead>
                <tbody>
                    {
                        items?.orders && items?.orders?.map((item:any)=>(
                            <tr key={item?.id}>
                                <td>
                                    <Link to={`/our-clients/client-details/${item?.user_id}`} className={styles['appointments__table-link']}>
                                        {item?.user_name}
                                    </Link>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.user_phone}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.paid_amount} {t('AED')}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.created_at}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.payment_method}</p>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default GuestIncomesReportsTable