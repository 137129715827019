import { useTranslation } from 'react-i18next'
import styles from './AddUpdateOrders.module.css'
import {ReactComponent as ClockIcon} from 'assets/icons/clockIcon.svg'
import {ReactComponent as GoogleIcon} from 'assets/icons/googleIcon.svg'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import MapModel from 'components/Global/Elements/MapModel/MapModel'
import {ReactComponent as UploadedIcon} from 'assets/icons/uploadedIcon.svg'
import Resizer from "react-image-file-resizer";
import { useNavigate, useParams } from 'react-router-dom'
function AddUpdateOrders() {
  const {t} =useTranslation()
  const userr =useSelector((state:any)=>state?.GlobalReducer?.user)

  const params = useParams()
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const [orderType,setOrderType]=useState('new')
  const [users,setUsers]=useState([])
  const [products,setProducts]=useState([])
  const [isLoading,setIsLoading]=useState(false)
  const [images,setImages]=useState<any>([])
  const [latitude,setLatitude] =useState('')
  const [longitude,setLongitude] =useState('')
  
  const [userDetails,setUserDetails]=useState({} as any)
  const [user,setUser]=useState('')
  const [product,setProduct]=useState('')
  const [address,setAddress]=useState('')
  const [notes,setNotes]=useState('')
  const [buyingDate,setBuyingDate]=useState('')
  const [clientJoiningDate,setClientJoiningDate]=useState('')
  const [subscribePeriod,setSubscribePeriod]=useState('')
  const [warrantyStatus,setWarrantyStatus]=useState('')
  const [receiptNumber,setReceiptNumber]=useState('')
  const [addressLink,setAddressLink]=useState('')
  const [orderPrice,setOrderPrice]=useState('')
  const [item,setItem]=useState<any>()
  
  function getItem(){
    setIsLoading(true)
    axiosConfig.get(`/orders/single-order/${params?.id}`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        
      }
    }).then(res=>{
      setIsLoading(false)
      setItem(res.data?.data)
      setShow(true)
    }).catch(err=>{
      setIsLoading(false)
      console.log(err)
    })
  }
  
  function getUserDetails(){
    let link = userr?.user_type == "user" ? `single-user` :`single-user-by-id/${user}`
    axiosConfig.get(`/user/${link}`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(res=>{
      setUserDetails(res.data?.data)
    }).catch(err=>{
      console.log(err)
    })
  }

  function validateInputs(){
    let status =true
    if(!product){
      toast.error(t('Product Is Required'))
      status=false
    }
    // if(!phone){
    //   toast.error(t('User Phone Is Required'))
    //   status=false
    // }
    // if(!emailPattern.test(email)){
    //   toast.error(t('User Email Is Required'))
    //   status=false
    // }
    // if(!password){
    //   toast.error(t('User Password Is Required'))
    //   status=false
    // }
    return status
  }

  function addUpdateItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    if(userr?.user_type=='admin'){
      formData.append('user_id',user)
    }else{
      formData.append('user_id',userr?.id)
    }
    formData.append('product_id',product)
    formData.append('order_type',orderType)
    formData.append('receipt_number',receiptNumber)
    formData.append('user_latitude',latitude)
    formData.append('user_longitude',longitude)
    formData.append('order_required_price',orderPrice)
    formData.append('user_order_address_link',addressLink)
    formData.append('user_order_address',address)
    if(orderType=='maintenance'){
      formData.append('warranty_status',warrantyStatus)
      formData.append('buying_date',buyingDate)
      // formData.append('subscription_period',subscribePeriod)
      formData.append('additional_notes',notes)

      images?.forEach((image:any,index:number)=>{
        formData.append(`additional_images`,image)
      })
    }
    if(item){
      axiosConfig.put(`/orders/update-order/${params?.id}`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Order Updated Successfully`))
        if(userr?.user_type=='admin'){
          navigate('/orders')
        }else{
          navigate('/guest/orders')
        }
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }else{
      axiosConfig.post(`/orders/create-order`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Order Added Successfully`))
        if(userr?.user_type=='admin'){
          navigate('/orders')
        }else{
          navigate('/guest/orders')
        }
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }
  }

  useEffect(()=>{
    if(params?.id){
      getItem()
    }
  },[params])
  
  useEffect(()=>{
    if(item){
      setOrderType(item?.order_type)
      setUser(item?.user?.id)
      setProduct(item?.product?.id)
      setLatitude(item?.user_latitude)
      setLongitude(item?.user_longitude)
      setAddress(item?.user_order_address)
      setNotes(item?.additional_notes)
      setAddress(item?.address)
      setOrderPrice(item?.order_required_price)
      setSubscribePeriod(item?.subscription_period)
      // setWarrantyStatus(item?.warranty_status)
      if (item?.order_type=='maintenance'){
        setWarrantyStatus(item?.product?.warranty_status)
      }
      setBuyingDate(item?.buying_date)
      setReceiptNumber(item?.receipt_number)
      setAddressLink(item?.user_order_address_link)
      setImages(item?.additional_images)
    }
  },[item])

  function getAllUsers(){
    axiosConfig.get(`/user/all-users-without-pagintaion`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(res=>{
      setUsers(res.data?.data)
    }).catch(err=>{
      console.log(err)
    })
  }

  function getAllProducts(){
    axiosConfig.get(`/product/all-products-without-pagination`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        
      }
    }).then(res=>{
      setProducts(res.data?.data)
    }).catch(err=>{
      console.log(err)
    })
  }

  async function handleUploadedImages(e:any){
    let files =e.target.files
    let uploadedFile:any = [...images]
    if((files?.length+images?.length)>8){
        toast.error(t("You Can't upload more than 8 images"))
        return
    }
    files = await Promise.all([...files ].map(async(file) =>{
        let image:any = await new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                500,
                "PNG",
                100,
                0,
                (uri) => {
                    console.log(uri)
                    resolve(uri);
                },
                "file"
            );
        });
        Object.assign(image, {
            preview: URL.createObjectURL(image),
        })
        uploadedFile.push(image)
    }))
    setImages(uploadedFile)
  }

  function deleteImg(index:any){
    let modifiedImages=[...images]
    modifiedImages.splice(index,1)
    setImages(modifiedImages)
  }
  useEffect(()=>{
    getAllUsers()
    getAllProducts()
    getUserDetails()
  },[])

  useEffect(()=>{
    getUserDetails()
  },[user])
  useEffect(()=>{
    setClientJoiningDate(userDetails?.joining_date)
  },[userDetails])

  useEffect(()=>{
    if (orderType=='maintenance'){
      let selectedProduct:any = userDetails?.products?.filter((userProduct:any)=>userProduct?.product_id == product)?.[0]
      setWarrantyStatus(selectedProduct?.warranty_status)
    }
  },[product])

  return (
    <>
      <section className={styles['form-wrapper']}>
        <Container>
          <div className={styles['form__content-wrapper']}>
            <h1 className={styles['form__content-title']}>{params?.id?t('Update Order'):t('Add Order')}</h1>
            <form className={styles['form__content-form']}>
              <div className={styles['form__content-form-inputs-wrapper']}>
                <select 
                  className={styles['form__content-form-input']}
                  value={orderType} 
                  onChange={(e)=>setOrderType(e.target.value)}
                >
                  <option value='new'>{t('New Orders')}</option>
                  <option value='maintenance'>{t('Maintenance Orders')}</option>
                </select>
                {
                  userr?.user_type=='admin'&&
                    <select 
                    className={styles['form__content-form-input']}
                      value={user}
                      onChange={(e)=>setUser(e.target.value)}
                    >
                      <option value=''>{t('Please Select Client')}</option>
                      {
                        users?.map((user:any)=>(
                          <option value={user?.id} key={user?.id}>{user?.name}</option>
                        ))
                      }
                    </select>
                }
                <select 
                  className={styles['form__content-form-input']}
                  value={product}
                  onChange={(e)=>setProduct(e.target.value)}
                >
                  <option value=''>{t('Please Select Product')}</option>
                  {
                    (orderType=='maintenance')?
                      userDetails?.products?.map((product:any)=>(
                        <option value={product?.product_id} key={product?.product_id}>{product?.product_name}</option>
                      ))
                    :
                      products?.map((product:any)=>(
                        <option value={product?.id} key={product?.id}>{product?.title}</option>
                      ))
                  }
                </select>
                {
                  userr?.user_type=='admin' &&
                  <input 
                    type='text'
                    className={styles['form__content-form-input']}
                    placeholder={`${t('Order Price')}`}
                    value={orderPrice}
                    onChange={(e)=>setOrderPrice(e.target.value)}
                  />
                }
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Address Link')}`}
                  value={addressLink}
                  onChange={(e)=>setAddressLink(e.target.value)}
                />
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Address')}`}
                  value={address}
                  onChange={(e)=>setAddress(e.target.value)}
                />
                <button type='button' className={styles['form__content-form-input']} onClick={()=>setShow(prevState=>!prevState)}>
                  {t('Client Location')}
                  <GoogleIcon />
                </button>
                {
                  orderType=='maintenance' &&
                  <>
                  <input 
                    type='datetime-local'
                    className={styles['form__content-form-input']}
                    placeholder={`${t('Joining date')}`}
                    value={buyingDate}
                    onChange={(e)=>setBuyingDate(e.target.value)}
                  />
                  <input 
                    type='text'
                    className={styles['form__content-form-input']}
                    placeholder={`${t('Client Joining date')}`}
                    value={clientJoiningDate}
                    onChange={(e)=>setClientJoiningDate(e.target.value)}
                    disabled
                  />
                  <input 
                    type='text'
                    className={styles['form__content-form-input']}
                    placeholder={`${t('Receipt Number')}`}
                    value={receiptNumber}
                    onChange={(e)=>setReceiptNumber(e.target.value)}
                  />

                  <select
                    className={styles['form__content-form-input']}
                    value={warrantyStatus}
                    onChange={(e)=>setWarrantyStatus(e.target.value)}
                    disabled
                  >
                    <option value=''>{t('Please Select Warranty Status')}</option>
                    <option value='in_warranty'>{t('In Warranty')}</option>
                    <option value='out_warranty'>{t('Out Warranty')}</option>
                  </select>

                  <div className='d-flex p-3'>
                    <label className={styles['form__content-form-input-text']} htmlFor='uploadImages'>
                      <UploadedIcon/>
                      {t('Upload Images')}
                    </label>
                    <input
                        type='file'
                        className='d-none'
                        id='uploadImages'
                        onChange={(e:any) => handleUploadedImages(e)}
                        multiple
                    />
                  </div>
                  </>
                }
              </div>
              {
                orderType=='maintenance' && 
                <>
                  <div className='d-flex pt-4'>
                      <textarea 
                        className={styles['form__content-form-input']}
                        placeholder={`${t('Issue Details')}`}
                        rows={5}
                        value={notes}
                        onChange={(e)=>setNotes(e.target.value)}
                      ></textarea>
                    </div>
                    <div className={styles['form__content-uploaded-images-wrapper']}>
                      {
                        images&&images.map((img:any,index:any)=>(
                            <div key={index} className={styles['form__content-uploaded-image-wrapper']}>
                                <img src={img?.preview ?img?.preview:img} alt='img' className={styles['form__content-uploaded-image']}/>
                                <button type='button' onClick={()=>{deleteImg(index)}} className={styles['form__content-uploaded-image-delete-icon']}>X</button>
                            </div>
                        ))
                      }
                    </div>
                </>
              }
              <button 
                type='button' 
                className={styles['form__content-form-submit']}
                onClick={addUpdateItem} 
                disabled={isLoading}>
                {
                  !isLoading?
                  <>{params?.id?t('Update'):t('Add Order')} </>
                  :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                }
              </button> 
            </form>
            <MapModel 
              setLatitude={setLatitude} 
              setLongitude={setLongitude} 
              // isItemLoading={isItemLoading} 
              longitude={longitude} 
              latitude={latitude}
              show={show}
              setShow={setShow}
            />
          </div>
        </Container>
        
      </section>
    </>
  )
}

export default AddUpdateOrders